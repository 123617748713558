import { IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergAlert from "components/IcebergAlert";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import { useState } from "react";
import { Modal, Form, Table, Button, Card } from "react-bootstrap";
import AdApplicationsApi from "services/AdminportalenApi/AdApplicationsApi";
import { AdApplication, CreateApplicationCommand } from "services/AdminportalenApi/AdApplicationsApi/types";
import { Permission } from "services/AdminportalenApi/PermissionsApi/types";
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";
import ApiCallStatus from "services/ApiStatus";
import TilgangerIcons from "./TilgangerIcons";
import TilgangerTable from "./TilgangerTable";
import CommonLoader from "components/CommonLoader";

interface Props {
    show: boolean;
    onClose: () => void;
}

const CreateModal = (props: Props) => {

    const [adApplication, setAdApplication] = useState<AdApplication>({} as AdApplication);

    const [otherAdApplications, setOtherAdApplications] = useState<AdApplication[]>([]);

    const loadOtherAdApplicationsForTsKunde = async (tsKundeId: string) => {
        const filter = {
            tsKundeId: tsKundeId
        }
        const result = await AdApplicationsApi.get(filter, 1);
        setOtherAdApplications(result);
    };

    const handleTsKundeSelected = (selectedKunde?: TsKundeSearchDto) => {
        if (selectedKunde) {
            loadOtherAdApplicationsForTsKunde(selectedKunde.tsKundeId);

            // also set name of application to tsKunde name if no name already is set
            const name = adApplication?.name ? adApplication?.name : selectedKunde?.tsKundeNavn;
            setAdApplication({ ...adApplication, tsKundeId: selectedKunde?.tsKundeId, tsKundeNavn: selectedKunde?.tsKundeNavn, name: name });
        } else {
            setOtherAdApplications([]);
            setAdApplication({ ...adApplication, tsKundeId: undefined, tsKundeNavn: undefined });
        }
    }

    const handleOnBehalfOfTsKundeSelected = (selectedKunde?: TsKundeSearchDto) => {
        if (selectedKunde) {
            setAdApplication({ ...adApplication, tilgangerOnBehalfOfTsKundeId: selectedKunde?.tsKundeId })
        } else {
            setAdApplication({ ...adApplication, tilgangerOnBehalfOfTsKundeId: undefined })
        }
    }

    const handleNameChanged = (value: string) => {
        setAdApplication({ ...adApplication, name: value });
    };

    const [createdApp, setCreatedApp] = useState<AdApplication>();
    const [createStatus, setCreateStatus] = useState<ApiCallStatus>(ApiCallStatus.Idle);

    const handleSubmitClicked = async () => {
        const command: CreateApplicationCommand = {
            tsKundeId: adApplication.tsKundeId,
            name: adApplication.name,
            tilgangerOnBehalfOfTsKundeId: adApplication.tilgangerOnBehalfOfTsKundeId,
            tilganger: permissions
        };
        setCreateStatus(ApiCallStatus.Loading);
        try {
            const response = await AdApplicationsApi.create(command);
            if (typeof (response) === "object") {
                setExpanded(false);
                setCreatedApp(response as AdApplication);
                setCreateStatus(ApiCallStatus.Success);
            } else {
                setCreateStatus(ApiCallStatus.Error);
            }
        } catch (e) {
            setCreateStatus(ApiCallStatus.Error);
        }
    };

    const isSubmitDisabled = () => {
        if (createStatus === ApiCallStatus.Loading)
            return true;
        if (!adApplication.tsKundeId)
            return true;
        if (!adApplication.name)
            return true;
        return false;
    };

    const [permissions, setPermissions] = useState<Permission[]>([{} as Permission]);

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const handleClose = () => {
        setCreateStatus(ApiCallStatus.Idle);
        setCreatedApp(undefined);
        setAdApplication({} as AdApplication);
        setPermissions([{} as Permission]);
        props.onClose();
    };

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Opprett ny API tilgang
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Eier av API tilgangen</Form.Label>
                        <TsKundeTypeahead onHandleChange={handleTsKundeSelected} />
                        {otherAdApplications.length > 0 &&
                            <>
                                <Form.Text>
                                    Kunden har allerede {otherAdApplications.length} API tilganger registrert.
                                </Form.Text>
                                <Button style={{ padding: "0 0.2rem" }} size="sm" variant="link" onClick={toggleExpanded}>
                                    {expanded ? "Skjul" : "Vis mer"}
                                </Button>
                                {expanded && <AppList apps={otherAdApplications} />}
                            </>
                        }
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Navn på API tilgangen</Form.Label>
                        <Form.Control type="text" value={adApplication?.name || ''} onChange={e => handleNameChanged(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tilganger på vegne av (blir satt til eier av app hvis ingenting velges)</Form.Label>
                        <TsKundeTypeahead onHandleChange={handleOnBehalfOfTsKundeSelected} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <TilgangerTable addedPermissions={permissions} onAddedUpdated={setPermissions} existingPermissions={[]} onExistingUpdated={() => { }} />
                    </Form.Group>
                </Form>
                {createStatus === ApiCallStatus.Loading && <CommonLoader color="#003b6e" />}
                {createStatus === ApiCallStatus.Error && <IcebergAlert variant="danger">
                    <b>En feil oppsto!</b>
                </IcebergAlert>}
                {createdApp && <IcebergAlert variant="info">
                    <b>{createdApp.name} er opprettet</b> <br />
                </IcebergAlert>}
                <p>Brukere med rollen DevBruker kan hente clientId og secret fra <a target="_blank" href="https://tilganger.tradesolution.no/Clients">https://tilganger.tradesolution.no/Clients</a></p>
            </Modal.Body>
            <Modal.Footer>
                {createStatus === ApiCallStatus.Success && <IconButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>Lukk</IconButton>}
                {createStatus !== ApiCallStatus.Success &&
                    <>
                        <IconButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>
                            Avbryt
                        </IconButton>
                        <IconButton disabled={isSubmitDisabled()} variant="primary" icon="disk" className="col" onClick={handleSubmitClicked}>
                            Lagre
                        </IconButton>
                    </>}
            </Modal.Footer>
        </Modal >
    );
};

const AppList = (props: {
    apps: AdApplication[]
}) => {
    return (
        <Card>
            <Card.Body>
                <Table responsive borderless size="sm" hover style={{ marginBottom: "0" }}>
                    <tbody>
                        {props.apps.map(app =>
                            <tr key={app.objectId}>
                                <td>{app.name}</td>
                                <td className="fitContent">
                                    <TilgangerIcons application={app} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
}

export default CreateModal;