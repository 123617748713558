import React from 'react';
import { Row, Col } from 'react-bootstrap';

import TilgangerTable from './components/TilgangerTable';
import RolleTable from './components/RolleTable';

import Style from './index.module.css';

const TilgangerOgRoller: React.FC = () => {

  return (
    <div className={Style.container}>
      <Row style={{marginTop: '0.5em'}}>
        <Col md={8}>
          <h2 className={Style.mainHeader}>Tilganger</h2>
          <p>Lyst til å vite litt mer om <a target="_blank" href="https://eanreg.sharepoint.com/:fl:/g/contentstorage/CSP_e834a0b1-fcd7-424e-a431-21aec6545fe1/EZGMmwp98cpFtgytdRTkMFgBBABB6YnsIuOJzYzUs_QxGg?e=A9xSnp&nav=cz0lMkZjb250ZW50c3RvcmFnZSUyRkNTUF9lODM0YTBiMS1mY2Q3LTQyNGUtYTQzMS0yMWFlYzY1NDVmZTEmZD1iJTIxc2FBMDZOZjhUa0trTVNHdXhsUmY0UlRaREtTUFBtdEdzVFZVVTBTQW5lblRta29zdmFzRFRMTExwNEVqM1dEaSZmPTAxM1FPS0JCTVJSU05RVTdQUlpKQzNNREZOT1VLT0lNQ1kmYz0lMkYmYT1Mb29wQXBwJnA9JTQwZmx1aWR4JTJGbG9vcC1wYWdlLWNvbnRhaW5lciZ4PSU3QiUyMnclMjIlM0ElMjJUMFJUVUh4bFlXNXlaV2N1YzJoaGNtVndiMmx1ZEM1amIyMThZaUZ6WVVFd05rNW1PRlJyUzJ0TlUwZDFlR3hTWmpSU1ZGcEVTMU5RVUcxMFIzTlVWbFZWTUZOQmJtVnVWRzFyYjNOMllYTkVWRXhNVEhBMFJXb3pWMFJwZkRBeE0xRlBTMEpDVEVWR1IwWkVSRXMzVFVkT1FUSkxNMGRQVWxJMlRGQkZSbE0lM0QlMjIlMkMlMjJpJTIyJTNBJTIyOWJkZDg4YzktYmEyNS00NjRiLTkwN2EtMmQxNDFhYzVmZjAyJTIyJTdE">tilganger i Tradesoluition?</a>  </p>
          <TilgangerTable />
        </Col>
        <Col>
          <h2 className={Style.mainHeader}>Roller</h2>
          <p>Lyst til å vite hvordan rollene <a target="_blank" href="https://eanreg.sharepoint.com/:fl:/g/contentstorage/CSP_e834a0b1-fcd7-424e-a431-21aec6545fe1/EYsWQHVu_a5Jtunzrq_0mTMBMofZ5xkWVluUlhZml_YJcw?e=MSfppf&nav=cz0lMkZjb250ZW50c3RvcmFnZSUyRkNTUF9lODM0YTBiMS1mY2Q3LTQyNGUtYTQzMS0yMWFlYzY1NDVmZTEmZD1iJTIxc2FBMDZOZjhUa0trTVNHdXhsUmY0UlRaREtTUFBtdEdzVFZVVTBTQW5lblRta29zdmFzRFRMTExwNEVqM1dEaSZmPTAxM1FPS0JCTUxDWkFISzNYNVZaRTNOMlBUVjJYN0pHSlQmYz0lMkYmYT1Mb29wQXBwJnA9JTQwZmx1aWR4JTJGbG9vcC1wYWdlLWNvbnRhaW5lciZ4PSU3QiUyMnclMjIlM0ElMjJUMFJUVUh4bFlXNXlaV2N1YzJoaGNtVndiMmx1ZEM1amIyMThZaUZ6WVVFd05rNW1PRlJyUzJ0TlUwZDFlR3hTWmpSU1ZGcEVTMU5RVUcxMFIzTlVWbFZWTUZOQmJtVnVWRzFyYjNOMllYTkVWRXhNVEhBMFJXb3pWMFJwZkRBeE0xRlBTMEpDVEVWR1IwWkVSRXMzVFVkT1FUSkxNMGRQVWxJMlRGQkZSbE0lM0QlMjIlMkMlMjJpJTIyJTNBJTIyOWJkZDg4YzktYmEyNS00NjRiLTkwN2EtMmQxNDFhYzVmZWY1JTIyJTdE">brukes i våre applikasjoner?</a> </p>
          <RolleTable />
        </Col>
      </Row>
    </div>
  )
}

export default TilgangerOgRoller;