import { IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergAlert from "components/IcebergAlert";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import { useEffect, useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import AdApplicationsApi from "services/AdminportalenApi/AdApplicationsApi";
import { AdApplication, UpdateApplicationCommand } from "services/AdminportalenApi/AdApplicationsApi/types";
import PermissionsApi from "services/AdminportalenApi/PermissionsApi";
import { Permission } from "services/AdminportalenApi/PermissionsApi/types";
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";
import ApiCallStatus from "services/ApiStatus";
import useCopyToClipboard from "utils/hooks/useCopyToClipboard";
import TilgangerTable from "./TilgangerTable";
import CommonLoader from "components/CommonLoader";

interface Props {
    show: boolean;
    onClose: () => void;
    application?: AdApplication;
}

enum SecretStatus {
    NotLoaded, Loading, Loaded, NoAccess, Missing
}

const EditModal = (props: Props) => {

    const [value, copy] = useCopyToClipboard();

    const [secretStatus, setSecretStatus] = useState<SecretStatus>(SecretStatus.NotLoaded);

    const loadClientSecret = async () => {
        if (props.application) {
            try {
                setSecretStatus(SecretStatus.Loading);
                const secret = await AdApplicationsApi.getClientSecret(props.application.applicationId);
                if (!secret) {
                    setSecretStatus(SecretStatus.Missing);
                } else {
                    setSecretStatus(SecretStatus.Loaded);
                }
                setClientSecret(secret);
            } catch (e) {
                setSecretStatus(SecretStatus.NoAccess);
            }
        } else {
            setSecretStatus(SecretStatus.NotLoaded);
            setClientSecret(undefined);
        }
    };

    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [isSecretHidden, setIsSecretHidden] = useState(true);

    const togglePasswordVisibility = () => {
        setIsSecretHidden(!isSecretHidden);
    };

    const [existingPermissions, setExistingPermissions] = useState<Permission[]>([]);
    const [addedPermissions, setAddedPermissions] = useState<Permission[]>([]);

    const loadPermissions = async (objectId: string) => {
        if (!objectId) {
            setExistingPermissions([]);
        }
        else {
            const p = await PermissionsApi.getByObjectId(objectId);
            setExistingPermissions(p);
        }
    };

    const [name, setName] = useState<string>(props.application?.name || '');
    const handleNameChanged = (value: string) => {
        setName(value);
    };

    const [tsKundeId, setTsKundeId] = useState<string | undefined>(props.application?.tsKundeId)
    const handleTsKundeSelected = (selectedKunde?: TsKundeSearchDto) => {
        if (selectedKunde) {
            setTsKundeId(selectedKunde.tsKundeId);
        } else {
            setTsKundeId(undefined);
        }
    }

    const [onBehalfOftsKundeId, setOnBehalfOfTsKundeId] = useState<string | undefined>(props.application?.tilgangerOnBehalfOfTsKundeId)
    const [onBehalfOftsKunde, setOnBehalfOfTsKunde] = useState<string | undefined>(props.application?.tilgangerOnBehalfOfTsKunde)
    const handleOnBehalfOfTsKundeSelected = (selectedKunde?: TsKundeSearchDto) => {
        if (selectedKunde) {
            setOnBehalfOfTsKundeId(selectedKunde.tsKundeId);
            setOnBehalfOfTsKunde(selectedKunde.tsKundeNavn);
        } else {
            setOnBehalfOfTsKundeId(undefined);
            setOnBehalfOfTsKunde(undefined);
        }
    }

    // reset component state when application changes
    useEffect(() => {
        setIsSecretHidden(true);
        loadClientSecret();
        setName(props.application?.name);
        setTsKundeId(props.application?.tsKundeId);
        setOnBehalfOfTsKundeId(props.application?.tilgangerOnBehalfOfTsKundeId);
        setOnBehalfOfTsKunde(props.application?.tilgangerOnBehalfOfTsKunde);
        loadPermissions(props.application?.applicationId);
    }, [props.application]);

    const [updateStatus, setUpdateStatus] = useState<ApiCallStatus>(ApiCallStatus.Idle);

    const handleSaveClicked = async () => {
        const command: UpdateApplicationCommand = {
            applicationId: props.application.applicationId,
            name: name,
            tsKundeId: tsKundeId,
            tilgangerOnBehalfOfTsKundeId: onBehalfOftsKundeId,
            permissions: existingPermissions.concat(addedPermissions)
        };
        setUpdateStatus(ApiCallStatus.Loading);
        try {
            await AdApplicationsApi.update(command);
            setUpdateStatus(ApiCallStatus.Success);
        } catch (e) {
            setUpdateStatus(ApiCallStatus.Error);
        }
    };

    const handleClose = async () => {
        setUpdateStatus(ApiCallStatus.Idle);
        setAddedPermissions([]);
        setExistingPermissions([]);
        props.onClose();
    }

    const isSubmitDisabled = () => {
        if (updateStatus === ApiCallStatus.Loading)
            return true;
        if (!tsKundeId)
            return true;
        if (!name)
            return true;
        if (!!props.application?.deactivatedDate)
            return true;
        return false;
    };

    return (
        <Modal size="lg" show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Endre
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Eier av API tilgangen</Form.Label>
                        {!props.application?.tsKundeId && (
                            <TsKundeTypeahead onHandleChange={handleTsKundeSelected} />
                        )}
                        {props.application?.tsKundeId && (
                            <Form.Control disabled type="text" value={props.application?.tsKundeNavn || ''} />
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Navn på API tilgangen</Form.Label>
                        <Form.Control type="text" value={name || ''} onChange={e => handleNameChanged(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>ClientId / ApplicationId</Form.Label>
                        <InputGroup>
                            <Form.Control disabled type="text" value={props.application?.applicationId} />
                            <IconButton variant="outline-primary" title="Kopier til utklippstavle" icon="copy-to-clipboard" onClick={() => copy(props.application?.applicationId)} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>ClientSecret</Form.Label>
                        <InputGroup>
                            <Form.Control disabled type={isSecretHidden ? "password" : "text"} value={clientSecret} />
                            <IconButton variant="outline-primary" disabled={secretStatus != SecretStatus.Loaded} icon="eye-open" title="Secret vises kun om du har riktige tilganger" onClick={togglePasswordVisibility} />
                            <IconButton variant="outline-primary" disabled={secretStatus != SecretStatus.Loaded} title="Kopier til utklippstavle" icon="copy-to-clipboard" onClick={() => copy(clientSecret)} />
                        </InputGroup>
                        <Form.Text className="error-text">
                            {secretStatus === SecretStatus.NoAccess && 'Du mangler rettigheter til å se client secret'}
                            {secretStatus === SecretStatus.Missing && 'Client secret mangler i KeyVault'}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Tilganger på vegne av (blir satt til eier av app hvis ingenting velges)</Form.Label>
                        {!onBehalfOftsKunde && props.application?.tilganger?.length === 0 && (
                            <TsKundeTypeahead onHandleChange={handleOnBehalfOfTsKundeSelected} />
                        )}
                        {((!onBehalfOftsKunde && props.application?.tilganger?.length > 0) || onBehalfOftsKunde) && (
                            <Form.Control disabled type="text" value={onBehalfOftsKunde || props.application?.tsKundeNavn} />
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <TilgangerTable existingPermissions={existingPermissions} addedPermissions={addedPermissions} onExistingUpdated={setExistingPermissions} onAddedUpdated={setAddedPermissions} />
                    </Form.Group>
                </Form>
                {updateStatus === ApiCallStatus.Loading && <CommonLoader color="#003b6e" />}
                {updateStatus === ApiCallStatus.Success && <IcebergAlert variant="success">
                    Applikasjon er oppdatert
                </IcebergAlert>}
                {updateStatus === ApiCallStatus.Error && <IcebergAlert variant="danger">
                    Noe gikk galt
                </IcebergAlert>}
                {!!props.application?.deactivatedDate && (
                    <IcebergAlert variant="info">
                        Du må aktivere API tilgangen for å kunne lagre
                    </IcebergAlert>
                )}
                <p>Brukere med rollen DevBruker kan hente clientId og secret fra <a target="_blank" href="https://tilganger.tradesolution.no/Clients">https://tilganger.tradesolution.no/Clients</a></p>
            </Modal.Body>

            <Modal.Footer>
                {updateStatus === ApiCallStatus.Success && <IconButton icon="close" variant="outline-primary" className="col" onClick={handleClose}>Lukk</IconButton>}
                {updateStatus !== ApiCallStatus.Success &&
                    <>
                        <IconButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>
                            Avbryt
                        </IconButton>
                        <IconButton disabled={isSubmitDisabled()} variant="primary" icon="disk" className="col" onClick={handleSaveClicked}>
                            Lagre
                        </IconButton>
                    </>}
            </Modal.Footer>
        </Modal >
    );
};

export default EditModal;