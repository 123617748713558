import { debounce } from "lodash";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import KontaktpersonerApi from "services/AdminportalenApi/KontaktpersonerApi";

interface Props {
  isInvalid: boolean;
  onSelected: (title: string) => void;
  disabled?: boolean;
  value?: string;
}

const KontaktpersonTitleTypeAhead = (props: Props) => {
  const [titles, setTitles] = useState<string[]>([]); 

  const searchForTitle = async (title: string) => {
		const result = await KontaktpersonerApi.findTitle(title);
		setTitles(result);
	};

  const handleChanged = (e: Option[]) => {
    if (e.length > 0) {
      let selected = e[0] as { label: string };
      props.onSelected(selected.label ? selected.label : selected.toString());
    }
  }  

  return (
    <Typeahead
      id="kontaktpersonTitleTypeahead"
      onChange={e => handleChanged(e)}
      onInputChange={debounce(text => searchForTitle(text), 300) }
      options={titles}
      allowNew={true}      
      onFocus={() => searchForTitle("")}
      isInvalid={props.isInvalid}
      disabled={props.disabled}
      defaultInputValue={props.value ?? ""}
    />
  );
};

export default KontaktpersonTitleTypeAhead;
