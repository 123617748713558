import axios from "axios";

import {
  BrukerWithTilgangerDto, BrukerSearchFilter, CreateBrukerCommand, UpdateBrukerCommand, UpdateBrukerTilgangerCommand,
  GiveAccessToBrukereCommand, RemoveAccessFromBrukereCommand, BrukerDto, AccessToTypeDto, RoleTypeDto, ResetPasswordCommand, ReinviteBrukerCommand, SignInDto, BrukerStorageLocationStatusDto, PendingBrukerInviteDto
} from "./types";

import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/Brukere';
const bulkBaseUrl = getBaseUrl() + '/BrukereBulk';
const pendingInvitesBaseUrl = getBaseUrl() + '/PendingBrukerInvites';

const BrukereApi = {
  getBrukereWithPermissions: async (filter: BrukerSearchFilter, page: number): Promise<BrukerWithTilgangerDto[]> => {
    filter.page = page;
    const response = await axios.post(`${baseUrl}/WithPermissions`, filter);
    return response.data;
  },


  getBrukereCount: async (filter: BrukerSearchFilter): Promise<number> =>
    axios.post(`${baseUrl}/Count`, filter).then(response => response.data),

  createBruker: async (command: CreateBrukerCommand): Promise<BrukerDto> =>
    axios.post(baseUrl, command).then(response => response.data),

  updateBruker: async (command: UpdateBrukerCommand): Promise<BrukerDto> =>
    axios.put(baseUrl, command).then(response => response.data),

  deleteBruker: async (brukerId: string) =>
    axios.delete(`${baseUrl}/${brukerId}`).then(response => response.data),

  toggleStatus: async (brukerId: string) =>
    axios.put(`${baseUrl}/${brukerId}/togglestatus`).then(response => response.data),

  updateBrukerTilganger: async (command: UpdateBrukerTilgangerCommand) =>
    axios.put(`${baseUrl}/Tilganger`, command).then(response => response.data),

  resetPassword: async (command: ResetPasswordCommand) =>
    axios.post(`${baseUrl}/ResetPassword`, command).then(response => response.data),

  reinviteBruker: async (objectId: string, command: ReinviteBrukerCommand) : Promise<BrukerDto> =>
    axios.put(`${baseUrl}/${objectId}/reinvite`, command).then(response => response.data),

  giveAccessToBrukere: async (command: GiveAccessToBrukereCommand) =>
    axios.post(`${bulkBaseUrl}/GiveAccess`, command),

  removeAccessFromBrukere: async (command: RemoveAccessFromBrukereCommand) =>
    axios.post(`${bulkBaseUrl}/RemoveAccess`, command),

  getAccessTos: async (): Promise<string[]> =>
    axios.get(`${baseUrl}/AccessTo`).then(response => response.data),

  getRoles: async (): Promise<string[]> =>
    axios.get(`${baseUrl}/Role`).then(response => response.data),

  search: async (value: string): Promise<BrukerDto[]> =>
    axios.get(`${baseUrl}/Search?searchValue=${value}`).then(response => response.data),

  checkStorageLocationStatus: async (email: string): Promise<BrukerStorageLocationStatusDto[]> =>
    axios.get(`${baseUrl}/CheckStorageLocationStatus?email=${email}`).then(response => response.data),

  getAccessToTypes: async (): Promise<AccessToTypeDto[]> =>
    axios.get(`${baseUrl}/AccessToTypes`).then(response => response.data),

  getRoleTypes: async (): Promise<RoleTypeDto[]> =>
    axios.get(`${baseUrl}/RoleTypes`).then(response => response.data),

  getLogIns: async (query: string): Promise<SignInDto[]> =>
  {
      let queryParameter = '';
      if(query)
        queryParameter = `?query=${query}`;
    let response = await axios.get(`${baseUrl}/LastSignIns${queryParameter}`);
    return response.data;
  },

  exportToExcel: async (filter: BrukerSearchFilter): Promise<any> => {

    let aditionalQueryParameters = '';
    if (filter.tsKundeId) {
      aditionalQueryParameters += `&tsKundeId=${filter.tsKundeId}`;
    }
    if (filter.onBehalfOfTsKundeId) {
      aditionalQueryParameters += `&onBehalfOfTsKundeId=${filter.onBehalfOfTsKundeId}`;
    }
    if (filter.accessTo) {
      aditionalQueryParameters += `&accessTo=${encodeURIComponent(filter.accessTo)}`;
    }
    if (filter.role) {
      aditionalQueryParameters += `&role=${encodeURIComponent(filter.role)}`;
    }
    if (filter.hasNoAccesses) {
      aditionalQueryParameters += `&hasNoAccesses=${filter.hasNoAccesses}`;
    }
    
    if (filter.jobFunctions) {
      aditionalQueryParameters += `&jobFunctions=${filter.jobFunctions.join(',')}`;
    }

    let response = await axios.get(`${baseUrl}/exporttoexcel?q=${filter.query}&statusFilter=${filter.statusFilter}${aditionalQueryParameters}`, { responseType: 'arraybuffer' });

    return response.data;
  },

  pendingInvites: async () : Promise<PendingBrukerInviteDto[]> => {
    let response = await axios.get(`${pendingInvitesBaseUrl}`);
    return response.data;
  },
  acceptedInvites: async () : Promise<PendingBrukerInviteDto[]> => {
    let response = await axios.get(`${pendingInvitesBaseUrl}/Accepted`);
    return response.data;
  },
  rejectedInvites: async () : Promise<PendingBrukerInviteDto[]> => {
    let response = await axios.get(`${pendingInvitesBaseUrl}/Rejected`);
    return response.data;
  },
  acceptPendingInvite: async (id: string) : Promise<string> => {
    let response = await axios.post(`${pendingInvitesBaseUrl}/${id}/AcceptPendingInvite`);
    return response.data;
  },
  rejectPendingInvite: async (id: string) : Promise<string> => {
    let response = await axios.post(`${pendingInvitesBaseUrl}/${id}/RejectPendingInvite`);
    return response.data;
  },
  findTitle: async (title: string) : Promise<string[]> => {
    var result = await axios.get(`${baseUrl}/FindTitle?title=${title}`);
    return result.data;
  },
};

export default BrukereApi;