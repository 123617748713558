import { Button, Form, FormText, Modal, Spinner, Table } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import { Abonnement } from 'services/AdminportalenApi/KundeKortApi/types';
import AbonnementApi from 'services/AdminportalenApi/AbonnementApi';
import { UpdateAbonnementCommand } from 'services/AdminportalenApi/AbonnementApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { useEffect, useState } from 'react';
import formatAsCurrency from 'utils/formatAsCurrency';
import TjenesterApi from 'services/AdminportalenApi/TjenesterApi';
import { PrisIntervallDto, TjenesteDto } from 'services/AdminportalenApi/TjenesterApi/types';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  tsKundeId: string;
  abonnement: Abonnement;
  onUpdated: () => void;
  onlyView: boolean;
}

const EditAbonnement = (props: Props) => {
  const toaster = useToaster();
  const [priceAdjustmentType, setPriceAdjustmentType] = useState<string>(props.abonnement.prisFaktor ? 'priceFactor' : props.abonnement.overstyrtPris ? 'overriddenPrice' : '');
  const [showFactorCalculator, setShowFactorCalculator] = useState<boolean>(false);

  const initialValues: UpdateAbonnementCommand = {
    abonnementId: props.abonnement.abonnementId,
    tjenesteId: props.abonnement.tjenesteId,
    tsKundeId: props.tsKundeId,
    validFromDate: props.abonnement.validFromDate,
    validToDate: props.abonnement.validToDate,
    overriddenPrice: props.abonnement.overstyrtPris,
    priceFactor: props.abonnement.prisFaktor,
    customComment: props.abonnement.customComment,
    itemCountForPricing: props.abonnement.itemCountForPricing,
  }

  const validate = (values: UpdateAbonnementCommand) => {
    let errors: any = {};
    return errors;
  }

  const [tjeneste, setTjeneste] = useState<TjenesteDto>(null);

  const loadTjeneste = async (tjenesteId: string) => {
    const t = await TjenesterApi.getById(tjenesteId);
    setTjeneste(t);
  };

  useEffect(() => {
    loadTjeneste(props.abonnement.tjenesteId);
  }, [props.abonnement]);

  const onSubmit = async (command: UpdateAbonnementCommand) => {
    if (!isNaN(parseFloat(command.overriddenPrice?.toString() || ""))) {
      command.overriddenPrice = Number(command.overriddenPrice);
    }
    else {
      command.overriddenPrice = null;
    }

    try {
      await AbonnementApi.updateAbonnement(command);
      toaster.success('Suksess', 'Abonnement oppdatert!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const onPriceAdjusted = (adjustmentType: string, value: any) => {    
    setPriceAdjustmentType(adjustmentType);
    if(!value){
      setPriceAdjustmentType(undefined);
      handleChange(adjustmentType, null);
    } else {
      handleChange(adjustmentType, value);
    } 
  }

  const onDesiredPriceEntered = (adjustmentType: string, price: any) => {   
    if(!price){
      setPriceAdjustmentType(null);
      handleChange(adjustmentType, '');
      return; 
    }
      
    const factor = price / props.abonnement.kalkulertPris;
    setPriceAdjustmentType(adjustmentType);    
    handleChange(adjustmentType, factor);
  }

  const onShowFactorCalculator = () => {
    setShowFactorCalculator(!showFactorCalculator);
  }

  const getStepAntall = (antall: number, prisIntervall: PrisIntervallDto[]) => {
      const intervals = prisIntervall.map((x) => x.antall);
      const step = intervals.reverse().find((x) => antall >= x);
      return step ?? -1;
    };

  const { values, errors, loading, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Endre abonnement</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Tjeneste</Form.Label>
            <Form.Control readOnly value={props.abonnement.tjenesteNavn} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig fra</Form.Label>
            <DatePicker
              onChange={date => handleChange('validFromDate', date)}
              selected={values.validFromDate}
              disabled={props.onlyView} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig til</Form.Label>
            <DatePicker
              onChange={date => handleChange('validToDate', date)}
              selected={values.validToDate}
              disabled={props.onlyView} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Ekstra beskrivelse (Vil stå på faktura)</Form.Label>
            <Form.Control
              value={values.customComment ?? ''}
              onChange={e => handleChange('customComment', e.target.value)}
              readOnly={props.onlyView} />
          </Form.Group>

          <h5>Prising</h5>

          {/* Nivåbasert prismodell */}
          {tjeneste?.aktivTjenestePris?.prisModelType === 4 && <Form.Group className="mb-3">
            <Form.Label>Antall</Form.Label>
            <Form.Control
              value={values.itemCountForPricing ?? ''}
              type='number'
              onChange={e => handleChange('itemCountForPricing', e.target.value)} />
          
          <Form.Text>Tjenestepris er nivåbasert, se tabell under</Form.Text>
          <Table>
            <thead>
              <tr>
                <th>Antall</th>
                <th>Pris</th>
              </tr>
              </thead>
              <tbody>
                {tjeneste.aktivTjenestePris.prisIntervall.map(i => 
                  <tr className={getStepAntall(values.itemCountForPricing, tjeneste?.aktivTjenestePris.prisIntervall) === i.antall ? 'table-success' : ''}>
                    <td>{i.antall}</td>
                    <td>{formatAsCurrency(i.pris)}</td>
                </tr>)}
              </tbody>
          </Table>
          </Form.Group>}

          {tjeneste?.aktivTjenestePris?.prisModelType !== 4 && <Form.Group className="mb-3">
            <Form.Label>Prisfaktor (f.eks. 0.5 for halv pris eller 2 for dobbel pris)</Form.Label>
            <Form.Control
              value={values.priceFactor ?? ''}
              type='number'           
              onChange={e => onPriceAdjusted('priceFactor', e.target.value)}
              disabled={priceAdjustmentType === 'overriddenPrice'}
              readOnly={props.onlyView} />
              <div>
                <FormText>Gjeldene pris: {formatAsCurrency(props.abonnement.kalkulertPris)}. </FormText>
                {priceAdjustmentType === 'priceFactor' && <FormText>Faktorjustert pris: {formatAsCurrency(Math.round(props.abonnement.kalkulertPris*values.priceFactor))}. </FormText>} 
                <FormText className='float-end'><a href='#' className='link-primary' onClick={() => onShowFactorCalculator()}>Beregningshelp?</a></FormText>
              </div>              
          </Form.Group>}
          {showFactorCalculator && 
            <Form.Group className="mb-3">
              <Form.Label>Prisfaktor kalkulator</Form.Label>
              <Form.Control
                placeholder='Skriv inn ønsket pris, så beregner vi faktoren for deg'             
                type='number'
                step={'0.000001'}          
                onChange={e => onDesiredPriceEntered('priceFactor', e.target.value)}
                disabled={priceAdjustmentType === 'overriddenPrice'} />
            </Form.Group>
            }
            <Form.Group className="mb-3">
              <Form.Label>Fastpris, OBS: 0 = gratis (Må justeres manuelt)</Form.Label>
              <Form.Control
                value={values.overriddenPrice ?? ''}
                disabled={priceAdjustmentType === 'priceFactor'}
                onChange={e => onPriceAdjusted('overriddenPrice', e.target.value)}
                readOnly={props.onlyView} />
            </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          {
            props.onlyView ?
              <Button
                variant="primary"
                onClick={() => props.setShow(false)}>
                Lukk
              </Button> :
              <>
                <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                  <IcebergIcon size='lg' icon='close' /> Avbryt
                </Button>
                <Button
                  variant="primary"
                  className="col"
                  type="submit"
                  disabled={loading || Object.keys(errors).length > 0}>
                  <IcebergIcon icon='disk' size='lg' /> Lagre
                  {
                    loading &&
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  }
                </Button>
              </>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditAbonnement;