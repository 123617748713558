import { IconName } from "@tradesolution/iceberg-ui-react";
import IconWrapper from "components/IconWrapper";
import { Alert, AlertProps } from "react-bootstrap";

interface Props extends AlertProps {
    icon?: IconName;
}

const IcebergAlert = (props: Props) => {

    const getIcon = () => {
        if (props.icon) {
            return props.icon;
        }
        switch (props.variant) {
            case "success":
                return "info";
            case "danger":
                return "alert";
            case "warning":
                return "alert";
            case "info":
                return "info";
            default:
                return "info";
        }
    }

    return (<Alert {...props}>
        <span><IconWrapper icon={getIcon()} variant={props.variant} />
        {props.children}</span>        
    </Alert>);
};

export default IcebergAlert;