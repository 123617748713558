import { useState } from 'react';
import { Modal, Form, Row, Col, Spinner, InputGroup, Alert, Table } from 'react-bootstrap';

import cx from 'classnames';

import TsKundeTypeahead from 'components/TsKundeTypeahead';

import TilgangRow from './components/TilgangRow';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { BrukerTilgangCommand, CreateBrukerCommand } from 'services/AdminportalenApi/BrukereApi/types';

import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';
import { IcebergIcon, IcebergTextTooltip, IconButton } from '@tradesolution/iceberg-ui-react';
import EmailsApi from 'services/AdminportalenApi/EmailsApi';

import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';
import AiApi from './components/SmartPaste/Api';

import JobbfunksjonPicker from '../JobbfunksjonPicker';
import BrukerTitleTypeAhead from '../BrukerTitleTypeAhead';


interface Props {
	onClosed: () => void;
	onUpdated: (email: string) => void;
	initialTilganger?: BrukerTilgangCommand[];
	tsKundeId?: string;
}

const NyBruker = (props: Props) => {
	const toaster = useToaster();

	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [emailExists, setEmailExists] = useState<string>(null);
	const [onlyInAzure, setOnlyInAzure] = useState<boolean>(false);

	//Smart paste - loading
	const [smartpasteLoading, setsmartpasteLoading] = useState<boolean>(false);

	const generateRandomNumber = (min: number, max: number) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const initialValues: CreateBrukerCommand = {
		tsKundeId: props.tsKundeId ? props.tsKundeId : '',
		givenName: '',
		surname: '',
		email: '',
		otherMail: '',
		tilgangCommands: props.initialTilganger && props.initialTilganger.length > 0
			? props.initialTilganger
			: [{ tsKundeId: '', role: '', accessTo: '', key: generateRandomNumber(0, 10000) }]
	};

	const validate = (values: CreateBrukerCommand) => {
		let errors: any = {};

		if (!values.tsKundeId) {
			errors.tsKundeId = 'TsKunde må angis';
		}

		if (!values.givenName) {
			errors.givenName = 'Fornavn kan ikke være tomt';
		}

		if (!values.surname) {
			errors.surname = 'Etterrnavn kan ikke være tomt';
		}

		if (!values.email) {
			errors.email = 'E-post kan ikke være tomt';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'E-post har ugyldig format';
		}

		if (!values.tilgangCommands.some(x => x.tsKundeId && x.accessTo)) {
			errors.tilgangCommands = 'Bruker må ha minst en tilgang';
		}

		if (!values.tilgangCommands.every(x => x.tsKundeId && x.accessTo)) {
			errors.tilgangCommands = 'Alle tilganger må minimum være tilknyttet en kunde og en applikasjon. Rolle er valgfritt';
		}

		if (values.otherMail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.otherMail)) {
			errors.otherMail = 'Alternativ e-post har ugyldig format';
		}

		return errors;
	};

	const onSubmit = async (command: CreateBrukerCommand) => {
		try {
			await BrukereApi.createBruker(command);
			toaster.success('Suksess', 'Bruker opprettet!');
			handleClose();
			resetForm(undefined);
			props.onUpdated(command.email);
		} catch (err) {
			toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
		}
	};

	const { values, errors, touched, loading, handleChange, handleSubmit, resetForm, updateValues, submitDisabled } = useForm(initialValues, validate, onSubmit);

	const handleClose = () => {
		resetForm(undefined);
		setIsSearching(false);
		setEmailExists(null);
		setOnlyInAzure(false);
		props.onClosed();
	};

	const addRow = () => {
		const tilgangCommands = [...values.tilgangCommands, { tsKundeId: '', role: '', accessTo: '', key: generateRandomNumber(0, 10000) }];
		handleChange('tilgangCommands', tilgangCommands);
	};

	const deleteRow = (tilgang: BrukerTilgangCommand) => {
		handleChange('tilgangCommands', values.tilgangCommands.filter(x => x !== tilgang));
	};

	const handleUpdateTilgang = (tilgang: BrukerTilgangCommand, prop: string, value: string) => {
		const tilgangCommands = [...values.tilgangCommands];
		const tilgangCommand = tilgangCommands.find(x => x === tilgang);
		if (prop === 'tsKundeId') {
			tilgangCommand.tsKundeId = value;
		} else if (prop === 'role') {
			tilgangCommand.role = value;
		} else if (prop === 'accessTo') {
			tilgangCommand.accessTo = value;
		}

		handleChange('tilgangCommands', tilgangCommands);
	};

	const handleSearchEmail = async () => {
		if (!values.email || errors.email) return;
		setIsSearching(prev => true);
		setOnlyInAzure(false);
		const verifyResult = await EmailsApi.verify(values.email);
		if (!verifyResult.canBeUsed && !values.email.includes("@trades.no")) {
			setEmailExists('Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?');
			setIsSearching(prev => false);
			return;
		}
		const searchResults = await BrukereApi.checkStorageLocationStatus(values.email);
		const indexForUserWithEmailInProxyAdresses = searchResults.findIndex(x =>
			x.proxyAdresses.some(function (item) { return item.includes(values.email) }) &&
			x.email !== values.email);
		const indexForUserWithEmailInOtherMails = searchResults.findIndex(x => 
			x.otherMails.some(function (item) { return item.includes(values.email) }) &&
				x.email !== values.email);
		if (searchResults.findIndex(x => x.email.toLowerCase() === values.email.toLowerCase() && x.existsInDb === true) > -1) {
			setEmailExists('Bruker med denne e-posten finnes fra før');
		}
		else if (indexForUserWithEmailInProxyAdresses > -1) {
			setEmailExists('E-posten er allerede brukt på bruker med e-post ' + searchResults[indexForUserWithEmailInProxyAdresses]?.email);
		}
		else if (indexForUserWithEmailInOtherMails > -1) {
			setEmailExists('E-posten er allerede brukt som alternativ e-post på bruker ' + searchResults[indexForUserWithEmailInOtherMails]?.email);
		}
		else {
			setEmailExists(null);
		}		

		const indexForAzureOnlyUser = searchResults.findIndex(x => x.email.toLowerCase() === values.email.toLowerCase() && x.existsInDb === false);
		if (indexForAzureOnlyUser > -1) {
			setOnlyInAzure(true);
			values.givenName = searchResults[indexForAzureOnlyUser]?.givenName;
			values.surname = searchResults[indexForAzureOnlyUser]?.surname;
			values.mobile = searchResults[indexForAzureOnlyUser]?.mobile;
			values.jobTitle = searchResults[indexForAzureOnlyUser]?.jobTitle;
			let otherMailIndex = searchResults[indexForAzureOnlyUser]?.otherMails.findIndex(x => x !== values.email);
			if (otherMailIndex > -1) {
				values.otherMail = searchResults[indexForAzureOnlyUser]?.otherMails[otherMailIndex];
			}
		}
		setIsSearching(prev => false);
	};

	const handleTsKundeChanged = (tsKundeId: string) => {

		const copy = { ...values };

		copy.tsKundeId = tsKundeId;

		// if we have tilganger where tskundeid is not set, set it to the selected tskundeid
		copy.tilgangCommands.forEach(tilgang => {
			if (!tilgang.tsKundeId) {
				tilgang.tsKundeId = tsKundeId;
			}
		});

		// we are using the updateValues function here as we we are updating multiple values at once
		updateValues(copy);
	};

	const createAIPrompt = (text: string) => {
		return `
    Extract the following contact information from the text:
    - Email
    - First Name
    - Last Name
    - Company/Organization
    - Alternative Email
    - Phone Number
    - Title/Position

    The input text is: "${text}"
    
    Return the information in an array in this specific order:
    [email, fornavn, etternavn, ansatt, altEmail, tlf, tittel].
    Only respond in valid json format.
    `;
	};

	const getAIResponse = async (prompt: string) => {
		const response = await AiApi.ask(prompt);
		return response;
	}

	const handleSmartPaste = async () => {
		try {
			setsmartpasteLoading(true); //start loading
			const text = await navigator.clipboard.readText();
			console.log("Clipboard text: ", text); // Log clipboard text

			// Create AI prompt
			const aiPrompt = createAIPrompt(text);
			console.log("AI Prompt: ", aiPrompt);

			// Get AI response 
			const aiResponse = await getAIResponse(aiPrompt);
			console.log("AI Response: ", aiResponse);

			// Parse the AI response
			if (typeof (aiResponse) === "string") {
				console.error(aiResponse);
			} else {
				const [email, fornavn, etternavn, ansatt, altEmail, tlf, tittel] = aiResponse;

				updateValues({
					email,
					otherMail: altEmail,
					givenName: fornavn,
					surname: etternavn,
					jobTitle: tittel,
					mobile: tlf as E164Number,
					tsKundeId: ''
				});

				console.log("Updated formData: ", { email, fornavn, etternavn, ansatt, altEmail, tlf, tittel });
			}
		} catch (err) {
			console.error('Failed to read clipboard contents: ', err);
		} finally {
			setsmartpasteLoading(false); // Stop loading
		}
	};

	return (
		<Modal size="xl" centered show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Opprett ny bruker</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					{
						values.email?.includes("@trades.no") &&
						<Row>
							<Alert variant='info'>Trades brukere får Trades4242 som første passord. Dette må byttes ved første innlogging</Alert>
						</Row>
					}
					{
						onlyInAzure &&
						<Row>
							<Alert variant='info'>Brukeren kan opprettes i Adminportalen. Utfylt info er hentet fra bruker som allerede finnes i Azure</Alert>
						</Row>
					}
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>E-post</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control type="text" style={{ borderRight: 'hidden' }} value={values.email} onChange={e => handleChange('email', e.target.value)} onBlur={handleSearchEmail} isInvalid={(errors?.email || emailExists?.length > 0) && touched?.email} />
									{isSearching
										? <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.email || emailExists?.length > 0) && touched?.email })}>
											<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
										</InputGroup.Text>
										: <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.email || emailExists?.length > 0) && touched?.email })}>
											<IcebergIcon icon='email' />
										</InputGroup.Text>}
									<Form.Control.Feedback type="invalid">{emailExists?.length > 0 ? emailExists : errors?.email}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>Fornavn</Form.Label>
								<Form.Control type="text" value={values.givenName} onChange={e => handleChange('givenName', e.target.value)} isInvalid={errors?.givenName && touched?.givenName} />
								<Form.Control.Feedback type="invalid">{errors?.givenName}</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>Etternavn</Form.Label>
								<Form.Control type="text" value={values.surname} onChange={e => handleChange('surname', e.target.value)} isInvalid={errors?.surname && touched?.surname} />
								<Form.Control.Feedback type="invalid">{errors?.surname}</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>Ansatt hos TsKunde</Form.Label>
								<TsKundeTypeahead selectedTsKundeId={props.tsKundeId} onHandleChange={e => handleTsKundeChanged(e?.tsKundeId)} />
								{errors?.tsKundeId && touched?.tsKundeId && <div className={Style.invalidFeedback}>{errors?.tsKundeId}</div>}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<IcebergTextTooltip
									icon="circle-question"
									placement="right"
									tooltip="Jobbfunksjon brukes for målrettet kommunikasjon og filtrering. 
									Det er viktig at så mange som mulig blir tildelet en eller flere jobb funksjoner da dette øker kvaliteten på utvalg. 
									Jobb funksjoner er kategorier som Tradesolution bruker og disse er ofte forskjellige fra stillingstittelen som benyttes i andre firma"
								>
									<Form.Label>Jobbfunksjoner (Valgfritt)</Form.Label>
								</IcebergTextTooltip>

								<JobbfunksjonPicker
									onSelectionChanged={selectedFunctions => handleChange('jobFunctions', selectedFunctions)}
									mode="multiSelect"
									disabled={false}
									selected={values.jobFunctions || []}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<IcebergTextTooltip
									placement="right"
									icon="circle-question"
									tooltip="Stillingstittel er den tittelen en person har internt i en organisasjon."
								>
									<Form.Label>Stillingstittel (Valgfritt)</Form.Label>
								</IcebergTextTooltip>
								<BrukerTitleTypeAhead 
									onSelected={title => handleChange('jobTitle', title)}
									isInvalid={touched?.jobTitle && errors?.jobTitle}							
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>Alternativ e-post (Valgfritt)</Form.Label>
								<Form.Control type="text" value={values.otherMail} onChange={e => handleChange('otherMail', e.target.value)} isInvalid={errors?.otherMail && touched?.otherMail} />
								<Form.Control.Feedback type="invalid">{errors?.otherMail}</Form.Control.Feedback>
								{values.otherMail && values.otherMail === values.email && (
									<Form.Label>Er det virkelig nødvendig med en alternativ e-post som er helt lik e-post?</Form.Label>
								)}
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Label>Telefon (Valgfritt)</Form.Label>
								<PhoneInputWithCountrySelect
									labels={nb}
									countryOptionsOrder={['NO', 'SE', 'DK']}
									defaultCountry='NO'
									className='form-control'
									value={values.mobile}
									onChange={(num: E164Number) => handleChange('mobile', num)} />
								<Form.Control.Feedback type="invalid">
									{errors?.mobile}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col className='text-end'>
							<IconButton
								title='Smart Paste er en ny funksjonalitet som muliggjør automatisk utfylling av feltene. Du kan kopiere en tekst som inneholder kontaktinfo, trykke "SmartPaste".'
								icon="boxed-lightbulb"
								onClick={handleSmartPaste}
								variant='outline-primary'
								disabled={smartpasteLoading} //disable button while loading
							>
								{smartpasteLoading ?
									'Wait for the magic to happen!...'
									:
									'SmartPaste'
								}

							</IconButton>
						</Col>
					</Row>
					<Row>
						<Col>
							<h6>Tilganger</h6>
							<div className='table-responsive'>
								<Table borderless hover >
									<tbody>
										{
											values.tilgangCommands?.length > 0 &&
											values.tilgangCommands.map((tilgang, index) =>
												<TilgangRow
													key={tilgang.key}
													tilgang={tilgang}
													onUpdateTilgang={(prop, value) => handleUpdateTilgang(tilgang, prop, value)}
													onDeleteRow={() => deleteRow(tilgang)}
													tsKundeId={tilgang.tsKundeId} />
											)
										}
									</tbody>
								</Table>
							</div>
							{errors?.tilgangCommands && touched?.tilgangCommands && <div className={Style.invalidFeedback}>{errors?.tilgangCommands}</div>}
						</Col>
					</Row>
					<Row>
						<Col className='text-end'>
							<IconButton icon="plus" onClick={addRow}>
								Legg til
							</IconButton>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>

					<IconButton
						icon="close"
						variant="outline-primary"
						onClick={handleClose}
						className="col">
						Avbryt
					</IconButton>
					<IconButton
						icon="disk"
						variant="primary"
						disabled={submitDisabled}
						type="submit"
						className="col">
						<span>
							Lagre
							{
								loading &&
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
							}
						</span>
					</IconButton>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default NyBruker;